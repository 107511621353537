import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(''),
  routes: [
    {
      path: '/',
      name: 'slots',
      component: () => import('../views/ChallengeView.vue'),
    },
    {
      path: '/challenge',
      name: 'challenge',
      component: () => import('../views/ShowcaseView.vue'),
      props: (route) => ({ code: route.query.c }),
    },
    {
      path: '/rules',
      name: 'rules',
      component: () => import('../views/RulesView.vue'),
    },
    {
      path: '/games',
      name: 'games',
      component: () => import('../views/GameView.vue'),
    },
  ],
});

export default router;
