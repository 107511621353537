<script setup lang="ts">
import { ref, watch } from 'vue';
import { RouterView, useRoute } from 'vue-router';

let theme = localStorage.getItem('user-theme');
if (theme === undefined) {
  theme = 'light-mode';
}
const themeRef = ref(theme);

function darkClicked() {
  localStorage.setItem('user-theme', 'dark-mode');
  themeRef.value = 'dark-mode';
}

function lightClicked() {
  localStorage.setItem('user-theme', 'light-mode');
  themeRef.value = 'light-mode';
}

const routes = useRoute();
const currentRoute = ref<string | undefined>(routes.name?.toString());
watch(
  () => routes.name,
  (newVal, _oldVal) => {
    currentRoute.value = newVal?.toString();
  },
);
</script>

<template>
  <div class="content" :class="{ 'dark-mode': themeRef === 'dark-mode' }">
    <div class="theme-control">
      <button :disabled="themeRef === 'dark-mode'" @click="darkClicked">Dark Mode</button>
      |
      <button :disabled="themeRef !== 'dark-mode'" @click="lightClicked">Light Mode</button>
    </div>
    <div id="wrap">
      <header>
        <h1>
          <a href="/" title="Challenge this"
            ><router-link to="/">Challenge <span>this</span></router-link></a
          >
        </h1>
        <div class="link-box">
          <a class="top-link" title="Rules"
            ><router-link to="/rules" :class="{ current: 'rules' === currentRoute }"
              >Rules</router-link
            ></a
          >
          <a class="top-link" title="Games"
            ><router-link to="/games" :class="{ current: 'games' === currentRoute }"
              >Games</router-link
            ></a
          >
        </div>
        <a
          href="https://www.twitch.tv/laserbelch"
          title="LaserBelch's Twitch"
          target="_blank"
          class="twitch"
        >
          twitch.tv/laserbelch
        </a>
      </header>
      <RouterView />
      <a
        href="https://www.youtube.com/@OlexaYT"
        title="Olexa's Youtube"
        target="_blank"
        class="credits"
      >
        <img src="/images/olexa-logo.png" alt="" /> Inspired by Olexa's Pyramid</a
      >
    </div>
    <!-- #wrap -->
  </div>
</template>

<style scoped>
.content {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: var(--color-light-orange);
  color: var(--color-orange);
}

.dark-mode {
  background-color: var(--color-slate);
}

.theme-control {
  top: 15px;
  right: 45px;
  position: absolute;
  z-index: 99;
}

.theme-control button {
  background: transparent;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}
.theme-control button:disabled {
  color: #999;
  cursor: default;
}

header {
  padding: 50px;
  display: flex;
  justify-content: space-between;
}

h1 {
  font-size: 70px;
  line-height: 64px;
  text-transform: uppercase;
  margin-right: -1em;
}
h1 a {
  color: var(--color-orange);
}

h1 span {
  display: block;
  letter-spacing: 1em;
}

.link-box {
  position: absolute;
  left: 50%;
  margin-top: 15px;
  display: flex;
  transform: translateX(-50%);
}
.top-link {
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 2px;
}
.top-link a {
  color: var(--color-orange);
  padding: 15px;
}

.top-link a.current {
  color: #999;
  cursor: default;
}
header .twitch {
  font-size: 17px;
  font-weight: 700;
  padding: 15px 70px 0 0;
  background: url(/images/laser-avatar.png) no-repeat top right;
}

header .twitch:hover {
  color: var(--color-orange);
}

#app {
  overflow-x: hidden;
}

#wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  position: relative;
}

.credits {
  text-transform: uppercase;
  color: var(--color-orange);
  font-size: 14px;
  letter-spacing: 1px;
  position: absolute;
  bottom: 0;
  left: 25px;
  padding: 20px 0 14px;
}
.credits:hover {
  color: var(--color-blue);
}
.credits img {
  width: 50px;
  background-color: var(--color-light-orange);
  border: 3px solid var(--color-blue);
  border-radius: 8px;
  margin-right: 10px;
}

@media screen and (max-width: 1200px) {
  #wrap .link-box {
    position: static;
    transform: none;
  }
}
@media screen and (max-width: 1050px) {
  header {
    padding: 35px;
  }
  #app .theme-control {
    right: 29px;
    top: 7px;
  }
  .credits {
    margin-top: 35px;
  }
}
@media screen and (max-width: 800px) {
  #wrap header {
    flex-direction: column;
  }
  #wrap h1 {
    font-size: 17vw;
    line-height: 17vw;
    order: 3;
    margin: 20px 0 35px;
    overflow: hidden;
  }
  #wrap header .twitch {
    order: 1;
    padding: 15px 0 15px 70px;
    background-position: left center;
  }
  #wrap .link-box {
    margin-top: 25px;
    order: 2;
  }
  #wrap .top-link a {
    padding: 15px 15px 15px 0;
  }
}
@media screen and (max-width: 500px) {
  #app .theme-control {
    left: 29px;
    right: auto;
  }
  .slot-machine {
    max-width: 65vw;
  }
  #wrap .slot {
    width: 65vw;
    height: 97.5vw;
  }
  #wrap .lever {
    left: -55px;
  }
  #wrap h1 {
    font-size: 16vw;
    line-height: 15.5vw;
  }
}
</style>
